import { gql } from '@apollo/client';

export const JoonDeviceListAttributes = gql`
  {
    _id
    desc
    tags
    tenantId
    tenant {
      name
    }
    ownerId
    ownerName
    wearerId
    wearerName
    koreSubscriptionId
    serialNo
    imsi
    eid
    iccid
    msisdn
    simStatus
    activatedAt
    callCenterId
    fwVer
    appVer
    appFlavor
    fallAppVer
    fallAppFlavor
    confVer
    auditStatus
    commVer
    envName
    last30DaysVoiceUsage {
      unit
      totalUsage
    }
    last30DaysDataUsage {
      unit
      totalUsage
    }
    last30DaysSmsUsage {
      unit
      totalUsage
    }
    last30DaysUpdatedAt
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    mgUdi
  }
`;

export const JoonDeviceSearchAttributes = gql`
  {
    _id
    desc
    tags
    tenantId
    tenantName
    userIds
    userNames
    ownerId
    ownerName
    wearerId
    wearerName
    serialNo
    imsi
    iccid
    msisdn
    callCenterId
    fwVer
    appVer
    appFlavor
    fallAppVer
    fallAppFlavor
    confVer
    auditStatus
    commVer
    notes
    envName
    updatedAt
    mgUdi
  }
`;

export const JoonDeviceDetailedAttributes = gql`
  {
    _id
    desc
    tags
    tenantId
    tenant {
      _id
      name
      callCenterCo
    }
    ownerId
    ownerName
    wearerId
    wearerName
    wearerPhone
    wearerEmail
    wearerAddress {
      address1
      address2
      city
      state
      country
      zipCode
    }
    koreSubscriptionId
    serialNo
    imsi
    eid
    iccid
    msisdn
    simStatus
    activatedAt
    callCenterId
    fwVer
    appVer
    appFlavor
    fallAppVer
    fallAppFlavor
    confVer
    auditStatus
    commVer
    notes
    envName
    last30DaysVoiceUsage {
      unit
      totalUsage
    }
    last30DaysDataUsage {
      unit
      totalUsage
    }
    last30DaysSmsUsage {
      unit
      totalUsage
    }
    wifiNetworks {
      _id
      ssid
      isOpen
      isWep
      safeZoneId
    }
    last30DaysUpdatedAt
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    mgUdi
  }
`;

export const joonDeviceQuery = gql`
  query JoonDevice($_id: ID!) {
    joonDevice(_id: $_id) ${JoonDeviceDetailedAttributes}
  }
`;

export const createJoonDeviceMutation = gql`
  mutation createJoonDevice($joonDevice: JoonDeviceCreateInput!) {
    createJoonDevice(joonDevice: $joonDevice) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const updateJoonDeviceMutation = gql`
  mutation updateJoonDevice($joonDevice: JoonDeviceUpdateInput!) {
    updateJoonDevice(joonDevice: $joonDevice) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const updateJoonDeviceMetadataMutation = gql`
  mutation UpdateJoonDeviceMetadata($joonDevice: JoonDeviceMetadataUpdateInput!) {
    updateJoonDeviceMetadata(joonDevice: $joonDevice) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const allJoonDevicesQuery = gql`
  query AllJoonDevices($first: Int, $after: String, $filters: JoonDeviceFilters, $sortBy: [SortBy]) {
    allJoonDevices(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${JoonDeviceListAttributes}
      }
    }
  }
`;

export const searchJoon3DevicesQuery = gql`
  query SearchJoon3Devices($first: Int, $after: String, $filters: Joon3DeviceSearchFilters, $sortBy: [SortBy]) {
    searchJoon3Devices(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${JoonDeviceSearchAttributes}
      }
    }
  }
`;

export const deleteJoonDeviceMutation = gql`
  mutation deleteJoonDevice($_id: ID!) {
    deleteJoonDevice(_id: $_id) {
      deletedId
    }
  }
`;

export const bulkCreateJoonDevicesMutation = gql`
  mutation BulkCreateJoonDevices($joonDevices: [JoonDeviceCreateInput!]!) {
    bulkCreateJoonDevices(joonDevices: $joonDevices) {
      joonDevices ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const JoonDeviceCellUsageAttributes = gql`
  {
    _id
    timestampMs
    date
    imei
    iccid
    unit
    type
    totalUsage
  }
`;

export const allJoonDeviceCellUsagesQuery = gql`
  query AllJoonDeviceCellUsages($first: Int, $after: String, $filters: JoonDeviceCellUsageFilters, $sortBy: [SortBy]) {
    allJoonDeviceCellUsages(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${JoonDeviceCellUsageAttributes}
      }
    }
  }
`;

export const JoonDeviceEventTypes = {
  HB: {
    key: 'HB',
    label: 'Heartbeat',
  },
  PON: {
    key: 'PON',
    label: 'Power On',
  },
  POFF: {
    key: 'POFF',
    label: 'Power Off',
  },
  LB: {
    key: 'LB',
    label: 'Low Battery',
  },
  BR: {
    key: 'BR',
    label: 'Battery Recharged',
  },
  SOS: {
    key: 'SOS',
    label: 'SOS Call',
  },
  SOSF: {
    key: 'SOSF',
    label: 'SOS Call Finished',
  },
  SOSE: {
    key: 'SOSE',
    label: 'SOS Call Error',
  },
  SOSL: {
    key: 'SOSL',
    label: 'SOS Location',
  },
  SOSA: {
    key: 'SOSA',
    label: 'SOS Call Answered',
  },
  STAT: {
    key: 'STAT',
    label: 'Status Update',
  },
  FD: {
    key: 'FD',
    label: 'Fall Detected',
  },
  FR: {
    key: 'FR',
    label: 'Fall Report',
  },
  FN: {
    key: 'FN',
    label: 'Fall No Response',
  },
  FV: {
    key: 'FV',
    label: 'Fall Verified',
  },
  FC: {
    key: 'FC',
    label: 'Fall Cancelled',
  },
  GPS: {
    key: 'GPS',
    label: 'Gps Fix',
  },
  FAC: {
    key: 'FAC',
    label: 'Factory Reset',
  },
  FDE: {
    key: 'FDE',
    label: 'Fall Detection Enabled',
  },
  FDD: {
    key: 'FDD',
    label: 'Fall Detection Disabled',
  },
  QC: {
    key: 'QC',
    label: 'Quick Connect Acknowledgement',
  },
  WC: {
    key: 'WC',
    label: 'Wi-Fi Connect',
  },
  WD: {
    key: 'WD',
    label: 'Wi-Fi Disconnect',
  },
  USS: {
    key: 'USS',
    label: 'User Settings Sync',
  },
};

export const JoonLocationTypes = {
  GPS: {
    key: 'GPS',
    label: 'GPS',
  },
  NET: {
    key: 'NET',
    label: 'Network',
  },
};

export const AuditStatuses = {
  GOOD: {
    key: 'GOOD',
    label: 'Sent & Acknowledged',
    color: 'green',
  },
  UNSENT: {
    key: 'UNSENT',
    label: 'Not Sent',
    color: 'orange',
  },
  NO_ACK: {
    key: 'NO_ACK',
    label: 'Sent, Not Acknowledged',
    color: 'orange',
  },
  UNKNOWN: {
    key: 'UNKNOWN',
    label: 'Unknown',
  },
  AUDIT_ERROR: {
    key: 'AUDIT_ERROR',
    label: 'Error',
    color: 'red',
  },
};

export const BootAnimationScreens = [
  {
    key: 0,
    label: 'Factory',
  },
  {
    key: 1,
    label: 'Bay Alarm Medical',
  },
  {
    key: 2,
    label: 'Medical Guardian',
  },
  {
    key: 3,
    label: 'Theora Care Animated',
  },
  {
    key: 4,
    label: 'Theora Connect +',
  },
];

export const JoonDeviceEventAttributes = gql`
  {
    _id
    type
    tenantId
    userIds
    contactId
    deviceId
    timestampMs
    sentAt
    receivedAt
    signalLevel
    batteryLevel
    stepCount
    stepsSinceMidnight
    mTxBytes
    mRxBytes
    mBytesMs
    earfcn
    onSinceMMs
    onChargerSinceMMs
    screenOnSinceMMs
    sinceBootMs
    loc {
      type
      lat
      lon
      alt
      acc
      speed
      bearing
    }
    drain
    screenOnSecs
    onChargerSecs
    onSecs
    timezone
    isCharging
    isTurboMode
    iccid
    msisdn
    fwVer
    appVer
    appFlavor
    confVer
    commVer
    extraId
    wifiSsid
    userSettings {
      shakeToWake
      lockdownModeEnabled
      screenBrightness
      callVolume
      ringVolume
    }
    powerOffReason
    notes
  }
`;

export const allJoonDeviceEventsQuery = gql`
  query AllJoonDeviceEvents($first: Int, $after: String, $filters: JoonDeviceEventFilters, $sortBy: [SortBy]) {
    allJoonDeviceEvents(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node ${JoonDeviceEventAttributes}
      }
    }
  }
`;

export const searchJoonEventsQuery = gql`
  query SearchJoonEvents($first: Int, $after: String, $filters: JoonDeviceEventFilters, $sortBy: [SortBy]) {
    searchJoonEvents(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node ${JoonDeviceEventAttributes}
      }
    }
  }
`;

export const JoonDatasetListAttributes = gql`
  {
    _id
    timestampMs
    lastTimestampMs
    deviceId
    name
    tags
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const JoonDatasetAttributes = gql`
  {
    _id
    timestampMs
    lastTimestampMs
    deviceId
    name
    tags
    notes
    files {
      _id
      desc
      s3Key
      filename
      type
      tags
      parentId
      uploaded
      processed
      contentType
      size
      url
      metadata
      updatedAt
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    mgUdi
  }
`;

export const allJoonDatasetsQuery = gql`
  query AllJoonDatasets($first: Int, $after: String, $filters: JoonDatasetFilters, $sortBy: [SortBy]) {
    allJoonDatasets(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node ${JoonDatasetListAttributes}
      }
    }
  }
`;

export const joonDatasetQuery = gql`
  query JoonDataset($_id: ID!) {
    joonDataset(_id: $_id) ${JoonDatasetAttributes}
  }
`;

export const createJoonDatasetMutation = gql`
  mutation createJoonDataset($joonDataset: JoonDatasetCreateInput!) {
    createJoonDataset(joonDataset: $joonDataset) {
      joonDataset ${JoonDatasetAttributes}
    }
  }
`;

export const updateJoonDatasetMutation = gql`
  mutation updateJoonDataset($joonDataset: JoonDatasetUpdateInput!) {
    updateJoonDataset(joonDataset: $joonDataset) {
      joonDataset ${JoonDatasetAttributes}
    }
  }
`;

export const deleteJoonDatasetMutation = gql`
  mutation deleteJoonDataset($_id: ID!) {
    deleteJoonDataset(_id: $_id) {
      deletedId
    }
  }
`;

export const JoonDeviceDatatAttributes = gql`
  {
    _id
    type
    deviceId
    timestampMs
    datasetId
    ax
    ay
    az
    gx
    gy
    gz
    loc {
      type
      lat
      lon
      alt
      acc
      speed
      bearing
    }
    step
    cmc
  }
`;

export const allJoonDeviceDataQuery = gql`
  query AllJoonDeviceData($first: Int, $after: String, $filters: JoonDeviceDataFilters, $sortBy: [SortBy]) {
    allJoonDeviceData(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node ${JoonDeviceDatatAttributes}
      }
    }
  }
`;

export const joonDatasetDataQuery = gql`
  query JoonDatasetData($_id: ID!, $skip: BigInt, $limit: BigInt) {
    joonDatasetData(_id: $_id, skip: $skip, limit: $limit) ${JoonDeviceDatatAttributes}
  }
`;

export const sendPlaySoundCommandMutation = gql`
  mutation SendPlaySoundCommand($cmd: PlaySoundCommandInput!) {
    sendPlaySoundCommand(cmd: $cmd)
  }
`;

export const JoonDeviceConfigAttributes = gql`
  {
    _id
    version
    sosPhoneNumber
    deviceHeartbeatIntervalMs
    heartbeatIntervalMs
    fallDetectionEnabled
    fallDetectSettings {
      dataLengthBefore
      dataLengthAfter
      algorithmNo
      verifyFallOnDevice
    }
    lockdownModeEnabled
    screenBrightness
    callVolume
    ringVolume
    bootAnimation
    deviceShakeToWake
    shakeToWake
    saveLogs
    turboModeIntervalMs
    turboModeTimeoutMs
    callCenterSettings
    updatedAt
    wifiNetworks {
      _id
      ssid
      isOpen
      isWep
      safeZoneId
    }
  }
`;

export const joonDeviceConfigQuery = gql`
  query JoonDeviceConfig($_id: ID!) {
    joonDeviceConfig(_id: $_id) ${JoonDeviceConfigAttributes}
  }
`;

export const joonDeviceConfigWiFiQuery = gql`
  query JoonDeviceConfig($_id: ID!) {
    joonDeviceConfig(_id: $_id) {
      wifiNetworks {
        _id
        ssid
        isOpen
        isWep
        safeZoneId
      }
    }
  }
`;

export const JoonDeviceTenantConfigAttributes = gql`
  {
    _id
    version
    sosPhoneNumber
    lockdownModeEnabled
    screenBrightness
    callVolume
    ringVolume
    shakeToWake
    deviceShakeToWake
    fallDetectionEnabled
    deviceHeartbeatIntervalMs
    heartbeatIntervalMs
    transmitCode
    callCenterNumber
    whitelistNumbers
    updatedAt
  }
`;

export const joonDeviceTenantConfigQuery = gql`
  query JoonDeviceTenantConfig($_id: ID!) {
    joonDeviceTenantConfig(_id: $_id) ${JoonDeviceTenantConfigAttributes}
  }
`;

export const tenantUpdateConfigMutation = gql`
  mutation TenantUpdateConfig($config: TenantUpdateConfigInput!) {
    tenantUpdateConfig(config: $config) {
      config ${JoonDeviceTenantConfigAttributes}
    }
  }
`;

export const updateJoonDeviceConfigMutation = gql`
  mutation UpdateJoonDeviceConfig($config: UpdateJoonDeviceConfigInput!) {
    updateJoonDeviceConfig(config: $config) {
      config ${JoonDeviceConfigAttributes}
    }
  }
`;
export const updateJoonWifiNetworks = gql`
  mutation updateJoonWifiNetworks($deviceId: ID!, $wifiNetworks: [WifiNetworkInput]!) {
    updateJoonWifiNetworks(deviceId: $deviceId, wifiNetworks: $wifiNetworks) {
      config ${JoonDeviceConfigAttributes}
    }
  }
`;

export const sendApplicationUpdateCommandMutation = gql`
  mutation SendApplicationUpdateCommand($cmd: ApplicationUpdateCommandInput!) {
    sendApplicationUpdateCommand(cmd: $cmd)
  }
`;

export const sendFirmwareUpdateCommandMutation = gql`
  mutation SendFirmwareUpdateCommand($cmd: FirmwareUpdateCommandInput!) {
    sendFirmwareUpdateCommand(cmd: $cmd)
  }
`;

export const sendGetLogsCommandMutation = gql`
  mutation SendGetLogsCommand($cmd: SendLogsCommandInput!) {
    sendGetLogsCommand(cmd: $cmd)
  }
`;

export const envNameQuery = gql`
  query EnvironmentName {
    envName
  }
`;

export const kibanaUrlQuery = gql`
  query KibanaUrl {
    kibanaUrl
  }
`;

export const switchEnvironmentMutation = gql`
  mutation SwitchEnvironment($deviceId: ID!, $envName: String!) {
    switchEnvironment(deviceId: $deviceId, envName: $envName) {
      joonDevice {
        _id
      }
    }
  }
`;

export const requestHeartbeatMutation = gql`
  mutation RequestHeartbeat($_id: ID!) {
    requestHeartbeat(_id: $_id)
  }
`;

export const requestRebootMutation = gql`
  mutation RequestReboot($_id: ID!) {
    requestReboot(_id: $_id)
  }
`;

export const DeviceUserRoles = {
  CAREGIVER: {
    key: 'CAREGIVER',
    label: 'Caregiver',
  },
  ADMIN: {
    key: 'ADMIN',
    label: 'Admin',
  },
  OWNER: {
    key: 'OWNER',
    label: 'Owner',
  },
  WEARER: {
    key: 'WEARER',
    label: 'Wearer',
  },
};

export const removeJoonDeviceFromUserMutation = gql`
  mutation RemoveJoonDeviceFromUser($deviceId: ID! $userId: ID!) {
    removeJoonDeviceFromUser(deviceId: $deviceId, userId: $userId) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const addJoonDeviceToUserMutation = gql`
  mutation AddJoonDeviceToUser($deviceUser: JoonDeviceUserInput!) {
    addJoonDeviceToUser(deviceUser: $deviceUser) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const updateJoonDeviceUserMutation = gql`
  mutation UpdateJoonDeviceUser($deviceUser: JoonDeviceUserInput!) {
    updateJoonDeviceUser(deviceUser: $deviceUser) {
      joonDevice ${JoonDeviceDetailedAttributes}
    }
  }
`;

export const JoonDailyAttributes = gql`
  {
    _id
    deviceId
    date
    screeOnSecs
    onChargerSecs
    onSecs
    stepCount
    detectedFallCount
    verifiedFallCount
    cancelledFallCount
    noResponseFallCount
    callMadeFallCount
    sosCalls
    updatedAt
  }
`;

export const allJoonDailiesQuery = gql`
  query AllJoonDailies($first: Int, $after: String, $filters: JoonDailyFilters, $sortBy: [SortBy]) {
    allJoonDailies(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${JoonDailyAttributes}
      }
    }
  }
`;

export const createJoonDeviceActivityReportMutation = gql`
  mutation CreateJoonDeviceActivityReport(
    $filters: JoonDeviceFilters
    $sortBy: [SortBy]
  ) {
    createJoonDeviceActivityReport(filters: $filters, sortBy: $sortBy) {
      url
    }
  }
`;

export const allJoonDeviceLogsQuery = gql`
  query AllJoonDeviceLogs(
    $first: Int
    $after: String
    $filters: FileFilters
    $sortBy: [SortBy]
  ) {
    allFiles(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          _id
          desc
          s3Key
          filename
          type
          tags
          parentId
          uploaded
          processed
          contentType
          size
          url
          createdAt
          updatedAt
        }
      }
    }
  }
`;
