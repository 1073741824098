import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from './autocomplete';

export function useManyRemote(
  query,
  extract,
  first,
  filters,
  sortBy = undefined,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const term$ = useRef(null);
  // const [search, setSearch] = useState();
  const nextAfter = useRef();
  const hasNextPage = useRef();
  const totalCount = useRef();
  const after = useRef();
  // const [after, setAfter] = useState();
  const _data = useRef([]);
  const [data, _setData] = useState(_data.current);
  const setData = useCallback((value) => {
    _data.current = value;
    _setData(value);
  }, []);
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);
  const search = useRef('');
  const apolloClient = useApolloClient();
  const last = useRef();

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      setError(null);
      try {
        const result = await apolloClient.query({
          query,
          variables: {
            first,
            after: after.current,
            filters: {
              ...filters,
              search: search.current || undefined,
            },
            sortBy,
          },
          fetchPolicy: 'network-only',
        });
        const results = result && extract(result.data);
        if (results) {
          totalCount.current = results.totalCount;
          hasNextPage.current = results.pageInfo.hasNextPage;
          nextAfter.current = results.pageInfo.endCursor;
          if (after.current) {
            setData([
              ..._data.current,
              ...results.edges.map((edge) => edge.node),
            ]);
          } else {
            setData(results.edges.map((edge) => edge.node));
          }
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    }
    const inputs = JSON.stringify({
      query,
      extract,
      first,
      filters,
      sortBy,
      refetches,
    });
    if (inputs !== last.current) {
      last.current = inputs;
      _refetches.current = refetches;
      doAsyncStuff();
    }
  }, [
    filters,
    first,
    setData,
    refetches,
    apolloClient,
    query,
    sortBy,
    extract,
  ]);

  useEffect(() => {
    term$.current = new BehaviorSubject('__init__');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          if (term !== '__init__') {
            hasNextPage.current = null;
            nextAfter.current = null;
            // search.current = term;
            search.current = term;
            after.current = undefined;
            // setRefetches(refetches + 1);
            // return of();
            return of(setRefetches(_refetches.current + 1));
          } else {
            return of();
          }
        }),
      )
      .subscribe();
  }, [setData]);

  const _search = useCallback((term) => {
    if (term !== undefined) {
      term$.current.next(term);
    }
  }, []);

  const refetch = useCallback(() => {
    setRefetches(_refetches.current + 1);
  }, []);

  return {
    data,
    hasNextPage: hasNextPage.current,
    totalCount: totalCount.current,
    loading,
    error,
    next: () => {
      if (hasNextPage.current) {
        after.current = nextAfter.current;
        setRefetches(_refetches.current + 1);
      }
    },
    search: _search,
    refetch,
    reset: async () => {
      await apolloClient.resetStore();
      after.current = undefined;
      setRefetches(_refetches.current + 1);
    },
  };
}
