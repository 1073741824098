import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Typography, Form, Table, Button, Input, Space, Tooltip } from 'antd';
import {
  CodeOutlined,
  DeleteOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useManyRemote } from '../../../../shared/use-many-remote';
import {
  searchJoonEventsQuery,
  JoonDeviceEventTypes,
  JoonLocationTypes,
} from '../../constants';
import settings from '../../../../settings';

const { Title, Text } = Typography;

function JsonEvent({ event }) {
  const eventJson = JSON.stringify(event, null, 4);
  return eventJson.split('\n').map((line, index) => {
    let count = 0;
    for (let i = 0; i < line.length; i += 1) {
      let c = line[i];
      if (c !== ' ') {
        break;
      }
      count += 1;
    }
    return (
      <div key={index} style={{ paddingLeft: `${count / 2}ex` }}>
        {line.slice(count)}
      </div>
    );
  });
}

function JoonDeviceEvents({ joonDevice }) {
  const [deleting, setDeleting] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const [form] = Form.useForm();

  const initialFilters = useMemo(() => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    const untilDate = new Date();
    untilDate.setDate(untilDate.getDate() + 1);
    return {
      // from: fromDate.getTime(),
      // until: untilDate.getTime(),
      deviceId: joonDevice._id,
    };
  }, [joonDevice]);

  const filters = useRef(initialFilters);
  const {
    error,
    loading,
    data: events,
    refetch,
    search: eventSearch,
    next,
    hasNextPage,
  } = useManyRemote(
    searchJoonEventsQuery,
    (data) => data.searchJoonEvents,
    settings.querySize,
    filters.current,
    [{ key: 'timestampMs', order: 'DESC' }],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params,
    });
  }, []);

  const onSearchChange = useCallback(
    (changed) => {
      filters.current = {
        ...filters.current,
        ...Object.entries(changed).reduce((prev, [k, v]) => {
          prev[k] = v ? v : undefined;
          return prev;
        }, {}),
      };
      refetch();
    },
    [refetch],
  );
  return (
    <div style={{ paddingLeft: 1, paddingRight: 1 }}>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          {/* <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item> */}
        </Form>
        <Button
          icon={<ReloadOutlined />}
          onClick={() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7);
            const untilDate = new Date();
            untilDate.setDate(untilDate.getDate() + 1);
            filters.current = {
              from: fromDate.getTime(),
              until: untilDate.getTime(),
              deviceId: joonDevice._id,
            };
            refetch();
          }}
        >
          Refresh
        </Button>
      </div>
      <Table
        dataSource={events}
        loading={!events.length && loading}
        pagination={{ ...pagination, total: events.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Table.Column title="IMEI" dataIndex="deviceId" key="deviceId" />
        <Table.Column
          title="Event Type"
          dataIndex="type"
          render={(text, record) => {
            return (
              record.type &&
              JoonDeviceEventTypes[record.type] &&
              JoonDeviceEventTypes[record.type].label
            );
          }}
        />
        <Table.Column
          title="Timestamp"
          dataIndex="timestampMs"
          render={(text, record) => {
            return (
              record.timestampMs &&
              new Intl.DateTimeFormat(locale, {
                dateStyle: 'short',
                timeStyle: 'long',
              }).format(new Date(record.timestampMs))
            );
          }}
        />
        <Table.Column
          title="Signal Strength (RSRP)"
          dataIndex="signalLevel"
          render={(text, record) => {
            return record.signalLevel && `${record.signalLevel} dBm`;
          }}
        />
        <Table.Column
          title="Battery Percent"
          dataIndex="batteryLevel"
          render={(text, record) => {
            return record.batteryLevel && `${record.batteryLevel}%`;
          }}
        />
        <Table.Column
          title="Step Count"
          dataIndex="stepsSinceMidnight"
          render={(text, record) => {
            return (
              record.stepsSinceMidnight !== undefined &&
              record.stepsSinceMidnight !== null &&
              `${record.stepsSinceMidnight} steps`
            );
          }}
        />
        <Table.Column
          title="Location"
          dataIndex="loc"
          render={(text, record) => {
            return (
              record.loc && (
                <div>
                  <div>
                    {JoonLocationTypes[record.loc.type] &&
                      JoonLocationTypes[record.loc.type].label}
                  </div>
                  <div>{`${record.loc.lat}, ${record.loc.lon}`}</div>
                  <div>{`${new Intl.NumberFormat('en-US', {
                    style: 'unit',
                    unit: 'meter',
                  }).format(record.loc.acc)}`}</div>
                </div>
              )
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {/* <Tooltip title="View">
                <Button icon={<DeleteOutlined />} />
              </Tooltip> */}
              <Tooltip title={<JsonEvent event={record} />}>
                <Button icon={<CodeOutlined />} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && (
          <Button style={{ minWidth: 200 }} onClick={next} loading={loading}>
            Load More
          </Button>
        )}
      </div>
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
}

export default JoonDeviceEvents;
